<template>
	<v-app id="inspire">
		<template v-if="logado">
			<LoginDialog v-if="usuario.flagprimeiroacesso" />
			<v-navigation-drawer v-model="drawer" fixed>
				<v-row>
					<v-col cols="3" class="text-center my-3">
						<v-avatar :color="cores[posicao]" size="48" class="mx-3" @click="corAvatar">
							<span style="color: #FFF;" class="font-weight-bold">{{usuario.nome.substr(0,1)}}</span>
						</v-avatar>
					</v-col>
					<v-col cols="9" class="text-left">
						<h3 class="mt-3 text-truncate">{{ usuario.nome }}</h3>
						<p style="color:#555555;" class="ml-0 pl-0 text-truncate">{{usuario.grupo}}</p>
					</v-col>
				</v-row>
				<v-divider />
				<v-list nav dense>
					<v-list-item-group v-model="group" active-class="deep-primary--text text--accent-4">
						<template v-for="m in menuPrincipal">
							<template v-if="m.subMenuStatus">
								<v-list-group :prepend-icon="m.icon" :key="m.link">
									<template v-slot:activator>
										<v-list-item-content>
											<v-list-item-title>{{m.titulo}}</v-list-item-title>
										</v-list-item-content>
									</template>

									<v-list-item v-for="sm in m.subMenu" :key="sm.link" :to="sm.to" exact class="ml-3">
										<v-list-item-icon>
											<v-icon>{{sm.icon}}</v-icon>
										</v-list-item-icon>
										<v-list-item-title>{{sm.titulo}}</v-list-item-title>
									</v-list-item>
								</v-list-group>
							</template>
							<template v-else>
								<v-list-item :key="m.link" :to="m.to" exact>
									<v-list-item-icon>
										<v-icon>{{m.icon}}</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{m.titulo}}</v-list-item-title>
								</v-list-item>
							</template>
						</template>
						<v-list-item to="/meu-usuario" exact>
							<v-list-item-icon>
								<v-icon>mdi-account-circle</v-icon>
							</v-list-item-icon>
							<v-list-item-title>Meu usuário</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="this.permissao('chamado')" to="/chamado" exact>
							<v-list-item-icon>
								<v-icon>mdi-comment-question-outline</v-icon>
							</v-list-item-icon>
							<v-list-item-title>Chamados</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="permissao('projeto')" to="/projeto" exact>
							<v-list-item-icon>
								<v-icon>mdi-responsive</v-icon>
							</v-list-item-icon>
							<v-list-item-title>Desenvolvimento</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="permissao('liberacao_submenu')" to="/liberacao" exact>
							<v-list-item-icon>
								<v-icon>mdi-cart-check</v-icon>
							</v-list-item-icon>
							<v-list-item-title>Liberações Cimento</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="permissao('ferramenta')" to="/ferramenta" exact>
							<v-list-item-icon>
								<v-icon>mdi-hammer-screwdriver</v-icon>
							</v-list-item-icon>
							<v-list-item-title>Ferramentas</v-list-item-title>
						</v-list-item>
					</v-list-item-group>
				</v-list>
				<v-row>
					<v-col cols="12" class="text-center mt-3">
						<v-btn color="primary" rounded class="font-weight-bold" to="/login/sair">Sair</v-btn>
					</v-col>
				</v-row>
			</v-navigation-drawer>

			<v-app-bar height="62" app color="primary" dark flat elevation="2">
				<v-container fluid class="py-0 fill-height px-0 pl-1">
					<v-toolbar-title>
						<router-link to="/">
							<v-img max-height="40" max-width="155" src="./assets/icons/Gestor_Logo_.svg" />
						</router-link>
					</v-toolbar-title>

					<v-spacer />

					<template v-for="m in menuPrincipal">
						<template v-if="m.subMenuStatus">
							<v-menu :key="m.link" offset-y>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										class="hidden-md-and-down px-2"
										:key="m.link"
										color="white"
										text
										v-bind="attrs"
										v-on="on"
									>
										{{m.titulo}}
										<v-icon class="ml-1">mdi-chevron-down</v-icon>
									</v-btn>
								</template>

								<v-list>
									<v-list-item v-for="sm in m.subMenu" :to="sm.to" :key="sm.link" exact>
										<v-list-item-title>{{sm.titulo}}</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</template>
						<template v-else>
							<v-btn
								class="hidden-md-and-down px-2"
								:key="m.link"
								:to="m.to"
								color="white"
								text
							>{{m.titulo}}</v-btn>
						</template>
					</template>
					<Avatar />
					<v-app-bar-nav-icon @click="drawer = true" class="hidden-lg-and-up" />
				</v-container>
			</v-app-bar>

			<v-main class="grey lighten-4">
				<v-container fluid>
<!--					<Chat />-->
					<keep-alive>
						<router-view />
					</keep-alive>
				</v-container>
				<div style="text-align: center;" class="py-3">
					<p class="py-0 my-0">
						<img height="70" src="./assets/icons/logo_footer.svg" />
					</p>
				</div>
			</v-main>
		</template>
		<v-main v-else style="background-color: #023449;">
			<v-container class="fill-height">
				<Login />
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import Login from "./Components/Login/Login.vue";
import Avatar from "./Components/Widgets/Avatar.vue";
import LoginDialog from "./Components/Login/LoginDialog.vue";
import axios from "axios";
import { mapMutations, mapState } from "vuex";
// import Chat from "./Components/Widgets/Chat.vue";

export default {
	components: { Login, Avatar, LoginDialog },
	data: () => ({
		cores: [
			"accent",
			"red",
			"green",
			"blue",
			"orange",
			"purple",
			"indigo",
			"deep-purple",
		],
		posicao: 0,
		menuPrincipal: [],
		drawer: false,
		group: null,
	}),
	computed: {
		...mapState([
			"usuario",
			"sessaoChave",
			"backendUrl",
			"logado",
			"primeiroAcesso",
		]),
	},
	methods: {
		...mapMutations(["uLogar"]),
		async validarToken() {
			const json = localStorage.getItem(this.sessaoChave);
			const sessao = JSON.parse(json);

			if (!sessao) {
				this.$store.commit("uLogar", null);
				return this.$router.push("/login");
			}

			axios.defaults.headers.common["token"] = sessao.token;

			await axios
				.post(
					`${this.backendUrl}login/sessao_token_validar`,
					{ token: sessao.token },
					{ token: sessao.token }
				)
				.then((res) => {
					if (res.data) {
						this.$store.commit("uLogar", {
							...sessao,
							logado: true,
						});
						if (this.$route.path == "/login") {
							return this.$router.push("/");
						}
					} else {
						localStorage.removeItem(this.sessaoChave);
						return this.$router.push("/login");
					}
				});
		},
		montarMenu() {
			let menu = [
				{
					titulo: "Dashboard",
					to: "/",
					icon: "mdi-bullseye-arrow",
					subMenu: [],
					subMenuStatus: false,
				},
			];

			if (this.permissao("venda_menu")) {
				menu.push({
					titulo: "Vendas",
					to: "/venda",
					icon: "mdi-file-document",
					subMenu: [],
					subMenuStatus: true,
				});
			}

			if (this.permissao("aluguel_equipamento_menu")) {
				menu.push({
					titulo: "Aluguel",
					to: "/aluguel-equipamento",
					icon: "mdi-file-document",
					subMenu: [],
					subMenuStatus: false,
				});
			}

			if (this.permissao("cliente_menu")) {
				menu.push({
					titulo: "Clientes",
					icon: "mdi-text-box-outline",
					subMenu: [],
					subMenuStatus: true,
				});
			}

			if (this.permissao("compras_menu")) {
				menu.push({
					titulo: "Compras",
					to: "/compras",
					icon: "mdi-account-group-outline",
					subMenu: [],
					subMenuStatus: true,
				});
			}

			if (this.permissao("gpp_menu")) {
				menu.push({
					titulo: "GPP",
					to: "/gpp",
					icon: "mdi-account-group-outline",
					subMenu: [
						{
							titulo: "GPP",
							to: "/gpp",
							icon: "mdi-text-box-outline",
						},
					],
					subMenuStatus: false,
				});
			}

			if (this.permissao("departamento_pessoal_menu")) {
				menu.push({
					titulo: "DP & RH",
					to: "/departamento-pessoal",
					icon: "mdi-account-group-outline",
					subMenu: [],
					subMenuStatus: false,
				});
			}

			if (this.permissao("filial_menu")) {
				menu.push({
					titulo: "Filiais",
					icon: "mdi-store-outline",
					subMenu: [
						{
							titulo: "Metas e Filiais",
							to: "/filial",
							icon: "mdi-store-outline",
						},
					],
					subMenuStatus: true,
				});
			}

      if (this.permissao("cd_menu")) {
        menu.push({
          titulo: "CD",
          to: "/cd",
          icon: "mdi-truck",
          subMenu: [],
          subMenuStatus: true,
        });
      }

			menu.forEach((v) => {
				if (v.titulo == "Compras") {
					if (this.permissao("compras_cadastro_produtos")) {
						v.subMenu.push({
							titulo: "Cadastro de produtos",
							to: "/compras",
							icon: "mdi-sync",
						});
					}
					if (this.permissao("unificador_pedidos")) {
						v.subMenu.push({
							titulo: "Unificador de pedidos",
							to: "/unificador",
							icon: "mdi-text-box-outline",
						});
					}
          if (this.permissao("controle_gaiola")) {
            v.subMenu.push({
              titulo: "Controle de Gaiolas",
              to: "/controle/gaiola",
              icon: "mdi-text-box-outline",
            });
          }
          if (this.permissao("price_bi")) {
            v.subMenu.push({
              titulo: "Pricing BI",
              to: "/pricing",
              icon: "mdi-text-box-outline",
            });
          }
				}

				if (v.titulo == "Vendas") {
					if (this.permissao("televenda_submenu")) {
						v.subMenu.push({
							titulo: "* Tele-vendas",
							to: "/venda",
							icon: "mdi-text-box-outline",
						});
					}

					if (this.permissao("concreto_submenu")) {
						v.subMenu.push({
							titulo: "* Venda de Concreto",
							to: "/concreto",
							icon: "mdi-text-box-outline",
						});
					}

					if (this.permissao("comissao_vendas")) {
						v.subMenu.push({
							titulo: "* Vendas e comissões",
							to: "/comissao/simulador",
							icon: "mdi-text-box-outline",
						});
					}

          if (this.permissao("etiquetas_menu")) {
            v.subMenu.push({
              titulo: "Etiquetas",
              to: "/etiqueta",
              icon: "mdi-tag-multiple",
            });
          }

          if (this.permissao("central_contatos_menu")) {
            v.subMenu.push({
              titulo: "Máquina de Vendas",
              to: "/omnichat",
              icon: "mdi-robot",
            });
          }
				}

				if (v.titulo == "Clientes") {
          if (this.permissao("submenu_manutencao_cliente")) {
            v.subMenu.push({
              titulo: "Manutenção de Clientes",
              to: "/cliente",
              icon: "mdi-text-box-outline",
            });
          }

					if (this.permissao("analise_menu")) {
						v.subMenu.push({
							titulo: "Análise de Crédito",
							to: "/analise",
							icon: "mdi-file-document-outline",
						});
					}

					if (this.permissao("entrega_menu")) {
						v.subMenu.push({
							titulo: "Entregas",
							to: "/entrega",
							icon: "mdi-truck-outline",
						});
					}

					if (this.permissao("contrato_menu")) {
						v.subMenu.push({
							titulo: "Contratos",
							to: "/contrato",
							icon: "mdi-file-document-outline",
						});
					}

					if (this.permissao("servico_credito_menu")) {
						v.subMenu.push({
							titulo: "Serviços de Crédito",
							to: "/servicocredito",
							icon: "mdi-file-document-outline",
						});
					}

					if (this.permissao("parcelamento_menu")) {
						v.subMenu.push({
							titulo: "Parcelamentos e Crediário",
							to: "/parcelamento",
							icon: "mdi-file-document-outline",
						});
					}

					if (this.permissao("seguro_menu")) {
						v.subMenu.push({
							titulo: "Seguros",
							to: "/seguro",
							icon: "mdi-file-document-outline",
						});
					}

					if (this.permissao("sorteio_menu")) {
						v.subMenu.push({
							titulo: "Sorteios e Cupons",
							to: "/sorteio",
							icon: "mdi-file-document-outline",
						});
					}

					if (this.permissao("credu_menu")) {
						v.subMenu.push({
							titulo: "Credu",
							to: "/credu",
							icon: "mdi-file-document-outline",
						});
					}
				}

				if (v.titulo == "Filiais") {
					if (this.permissao("comissao_menu")) {
						v.subMenu.push({
							titulo: "Comissão",
							to: "/comissao",
							icon: "mdi-account-multiple-outline",
						});
					}

					if (this.permissao("usuario_menu")) {
						v.subMenu.push({
							titulo: "Usuários",
							to: "/usuario",
							icon: "mdi-account-multiple-outline",
						});
					}

					if (this.permissao("balanco_menu")) {
						v.subMenu.push({
							titulo: "Balanço",
							to: "/balanco",
							icon: "mdi-file-document-outline",
						});
					}

          if (this.permissao("remissao_menu")) {
            v.subMenu.push({
              titulo: "Remissões",
              to: "/remissao",
              icon: "mdi-file-document-outline",
            });
          }
				}

        if (v.titulo == "CD") {
          if (this.permissao("cd_menu_estoque_reserva_wms")) {
            v.subMenu.push({
              titulo: "Estoque e Reserva - CD",
              to: "/cd",
              icon: "mdi-text-box-outline",
            });
          }

          if (this.permissao("cd_menu_entrega_domicilio")) {
            v.subMenu.push({
              titulo: "Acompanhamento Logístico",
              to: "/entrega-domicilio",
              icon: "mdi-text-box-outline",
            });
          }
        }
			});

			if (this.permissao("marketing_menu")) {
				menu.push({
					titulo: "Marketing",
					to: "/marketing",
					icon: "mdi-image-multiple-outline",
					subMenu: [],
					subMenuStatus: false,
				});
			}

			if (this.permissao("ti_menu")) {
				menu.push({
					titulo: "Ti",
					to: "/ti",
					icon: "mdi-laptop",
					subMenu: [],
					subMenuStatus: false,
				});
			}

			this.menuPrincipal = menu;

		},
		corAvatar() {
			this.posicao = Math.floor(Math.random() * this.cores.length);
		},
		async init() {
			await this.validarToken();

			if (this.logado) {
				this.montarMenu();
			}
		},
	},
	mounted() {
		this.init();
	},
	watch: {
		logado: function () {
			this.init();
		},
	},
};
</script>

<style>
/* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
	position: absolute;
}

#create .v-btn--floating {
	position: relative;
}
</style>